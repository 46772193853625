import { Component } from "react";
import { connect } from "react-redux";
import { SDate, SLoad, SPage, STable2, SText, SView } from "servisofts-component";
import SSocket from "servisofts-socket";
import Botoncito from "../../Components/Botoncito";
import Model from "../../Model";
import sucursal_usuario from "../sucursal_usuario";


class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Export Base Datos",
      func: "_romeo2",
      parametros: {
        inicio: "2015-01-01",
        fin: "2028-02-01",
      },
      ...this.state,
    };
  }


  // nota me falta que se pueda mostrar por fecha,  en la base de datos
  // la cual enviare la fecha y tiene que mostrar

  componentDidMount() {
    this.getData();
    Model.usuario.Action.getAll({ force: true });
  }
  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: this.state.func,
      params: [
      ],
      ...this.params,
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }

  getAll() {
    var dase_datos = this.state.data;
    if (!dase_datos) return <SLoad type="skeleton" col={"xs-12"} height />;

    var usuarios = Model.usuario.Action.getAll();
    if (!usuarios) return <SLoad />;

    var aux = sucursal_usuario.Actions.getActive(this.props);
    if (!aux) return <SLoad />;
    var colorear = "";

    return (
      <STable2
        limit={15}
        data={dase_datos}
        cellStyle={{
          fontSize: 12,
        }}
        header={[
          { key: "index", label: "ÍNDICE", width: 50 },
          {
            key: "sucursal_descripcion", label: "SUCURSAL", width: 110,
            component: (e) => <SView ><SText>{`${e}`}</SText> </ SView>
          },
          {
            key: "fecha_factura", label: "FECHA EMISIÓN📅", width: 110, center: true, render: (item) => {
              if (!item) return "";
              return new SDate(item).toString("yyyy-MM-dd");
            }, component: (e) => <SView ><SText>{`${e}`}</SText> </ SView>
          },
          {
            key: "-fecha_factura", label: "HORA EMISIÓN📅", width: 100, center: true, render: (item) => {
              if (!item.fecha_factura) return "";
              return new SDate(item.fecha_factura).toString("hh:mm");
            }, component: (e) => <SView ><SText>{`${e}`}</SText> </ SView>
          },
          {
            key: "codigo_factura", label: "CÓDIGO FACTURA⚡", order: "desc", center: true, width: 120
          },
          { key: "nit", label: "NIT", width: 80 },
          { key: "razon_social", label: "RAZÓN SOCIAL", width: 180 },

          {
            key: "key_usuario_factura", label: "FACTURADOR", width: 140, render: (item) => {
              if (!item) return "-";



              return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}`;
            },
            component: (e) => {
              return < SView > <SText>{`${e}`}</SText> </ SView >
            }
          },


          {
            key: "-onpress98", renderExcel: (a) => "", label: "DESCARGAR", width: 100, component: obj => {
              return <Botoncito height={20} onPress={() => {
                console.log(obj)

                SSocket.sendPromise({
                  component: "facturacion",
                  type: "getFacturaPdfV2",
                  codigo_factura: obj?.codigo_factura + ""
                }).then(e => {
                  const byteCharacters = atob(e.data);
                  const byteNumbers = new Array(byteCharacters.length);
                  for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);
                  const blob = new Blob([byteArray], { type: 'application/pdf' });
                  const blobUrl = URL.createObjectURL(blob);
                  window.open(blobUrl, '_blank');
                  console.log(e);
                }).catch(e => {
                  console.error(e);
                })
              }} >{"Factura"}</Botoncito>
            }
          },




          {
            key: "paquete_venta_fecha_on", label: "FECHA COMPRA", width: 100, center: true, render: (item) => { return new SDate(item).toString("yyyy - MM - dd"); }, component: (e) => <SView ><SText>{`${e}`}</SText> </ SView>
          },
          { key: "paquete", label: "PAQUETE", width: 150, component: (e) => <SView ><SText>{`${e}`}</SText> </ SView> },
          { key: "paquete_dias", label: "DÍAS", center: true, width: 40 },
          { key: "paquete_precio", label: "PRECIO", center: true, width: 50 },
          {
            key: "participantes", label: "PARTICIPANTES", center: true, width: 90
          },
          {
            key: "clientes", label: "CLIENTES", width: 220, render: (item) => {
              let txt = "";
              if (Array.isArray(item)) {
                item.map((obj) => {
                  txt += (usuarios[obj]?.Nombres + " " + usuarios[obj]?.Apellidos ?? "");
                  txt += ((item.length > 1) ? ", " : "");
                })
              }
              return (txt).toUpperCase();
            }
          },
          { key: "pagado", label: "PAGADO", center: true, sumar: true, width: 80 },
          {
            key: "cajero_key", label: "ATC", width: 180, render: (item) => { return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}`; },
            component: (e) => <SView ><SText>{`${e}`}</SText> </ SView>
          },

          { key: "tipos_pago_descripcion", label: "TIPO PAGO", width: 90, component: (e) => <SView ><SText color={"cyan"} >{`${e}`}</SText> </ SView> },
        ]
        }
        filter={(item) => {
          // if (item.is_factura != false) return false;
          if (!item.fecha_factura_anulada) return false;
          // if (!item.fecha_factura_anulada || !item.codigo_factura) return false;
          return sucursal_usuario.Actions.isActive(
            item.sucursal_key,
            this.props
          );
        }}

      />
    );
  }
  render() {
    return (
      <SPage title={"REPORTE FACTURAS ANULADAS"} disableScroll center>
        {this.getAll()}
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(Test);
