import { Component } from 'react';
import { ScrollView, Text } from 'react-native';
import { connect } from 'react-redux';
import { ExportExcel, SButtom, SDate, SHr, SIcon, SLoad, SNavigation, SOrdenador, SPage, SText, STheme, SView } from 'servisofts-component';
import * as SPDF from 'servisofts-rn-spdf';
import SSocket from 'servisofts-socket';
import BarraSuperior from '../../../../Components/BarraSuperior';
import Botoncito from '../../../../Components/Botoncito';
import FotoPerfilUsuario from '../../../../Components/FotoPerfilUsuario';
import Model from '../../../../Model';
import { SSRolesPermisosValidate } from '../../../../SSRolesPermisos';
import Paquete from '../../../Paquete';
import Sucursal from '../../../Sucursal';
import sucursal_usuario from '../../../sucursal_usuario';
import PaquetesDeUsuarioV2 from './PaquetesDeUsuario/PaquetesDeUsuarioV2';

var finalData = {};

class ClientePerfilPage extends Component {
  static navigationOptions = ({ navigation }) => {
    return { headerShown: false, }
  }
  constructor(props) {
    super(props);
    this.key = SNavigation.getParam("key", false);
    this.bandera = false;
    this.state = {};
  }
  // componentDidMount() {
  //   if (this.key) {
  //     this.getData();
  //   }
  // }

  // getData() {
  // console.log("'" + this.state.parametros.inicio + "'", "'" + this.state.parametros.fin + "'");
  // retur
  // this.setState({ loading: "cargando", data_usuario: null });
  // SSocket.sendPromise({
  //   version: "2.0",
  //   component: "usuario",
  //   type: "get_last_history_by_key_usuario",
  //   service: "usuario",
  //   key_usuario: this.key
  // })
  //   .then((resp) => {
  //     this.setState({ loading: false, data_usuario: resp.data });
  //   })
  //   .catch((e) => {
  //     this.setState({ loading: false, error: e });
  //   });
  // }
  EditarPerfil() {
    return <SButtom style={{ width: 60, height: 30, position: "absolute", right: 0, top: 0, backgroundColor: "green", borderRadius: 2 }}
      onPress={() => { SNavigation.navigate("registro", { key: this.key }) }}>
      <Text style={{ fontSize: 16, color: STheme.color.text }}>EDITAR</Text>
    </SButtom>
  }

  valido_NombreCompleto(nombre, apellidos) {
    return <SText style={{ color: ((nombre ?? "").length < 3 || (apellidos ?? "").length < 3) ? "red" : STheme.color.text }}>{"Nombre Completo: " + (nombre ?? "") + " " + (apellidos ?? "")}</SText>;
  }

  valido_CI(nit) {
    return <SView col={"xs-12"} row >
      <SText color={(nit ?? "").length < 5 ? STheme.color.text : "cyan"} >{"CI: " + (nit ?? "")}   </SText>
      {(nit ?? "").length < 5 ? <SText backgroundColor='red' color="white" bold > error: el CI tiene menos de 6 dígitos </SText> : ""}
    </SView>
  }

  valido_Telefono(telefono) {
    return <SView col={"xs-12"} row >
      <SText color={STheme.color.text} >{"Telefono: " + (telefono ?? "")}   </SText>
      {(telefono ?? "").length < 8 || ((telefono ?? "").charAt(0) !== "7" && (telefono ?? "").charAt(0) !== "6" && (telefono ?? "").charAt(0) !== "+") ? <SText backgroundColor='red' color="white" bold > error: campo Telefono </SText> : ""}
    </SView>
  }

  valido_Correo(correo) {
    return <SText style={{ color: (correo ?? "").length < 12 ? "red" : "cyan" }}>{"Correo: " + (correo ?? "")} </SText>;
  }

  valido_Cumpleaños(Cumpleaños) {
    return <SView col={"xs-6"} row >
      <SText color={STheme.color.text} >{"F.Nacimiento: " + (Cumpleaños ?? "")}   </SText>
      {(Cumpleaños ?? "").length < 9 ? <SText backgroundColor='red' color="white" bold > error: campo Fecha Nacimiento </SText> : ""}
    </SView>
  }

  valido_Sexo(Cumpleaños) {
    return <SView col={"xs-6"} row >
      <SText color={STheme.color.text} >{"Género: " + (Cumpleaños ?? "")}</SText>
      {(Cumpleaños ?? "").length < 2 ? <SText backgroundColor='red' color="white" bold > error </SText> : ""}
    </SView>
  }
  valido_Festivo(Cumpleaños) {
    return <SView col={"xs-6"} row >
      <SText color={STheme.color.text} >{"Festivo: " + (Cumpleaños ?? "")}</SText>
      {(Cumpleaños ?? "").length < 2 ? <SText backgroundColor='#C70039' color="white" bold border={5} > error: registrar si es Papá/Mamá/ninguno </SText> : ""}
    </SView>
  }

  valido_RazonSocial(nombre) {
    return <SView col={"xs-12"} row >
      <SText color={(nombre ?? "").length < 5 ? STheme.color.text : "cyan"} >{"Razón Social: " + (nombre ?? "")}   </SText>
      {(nombre ?? "").length < 5 ? <SText backgroundColor='red' color="white" bold > error: La razón social tiene poco caracteres </SText> : ""}
    </SView>
  }

  valido_Nit(nit) {
    return <SView col={"xs-12"} row >
      <SText color={(nit ?? "").length < 4 ? STheme.color.text : "cyan"} >{"Nit: " + (nit ?? "")}   </SText>
      {(nit ?? "").length < 4 ? <SText backgroundColor='red' color="white" bold > error: el CI/NIT tiene menos de 5 dígitos </SText> : ""}
    </SView>
  }

  valido_Cumpleaños2(Cumpleaños) {
    var fecha = Cumpleaños;
    var fechaObj = new Date(fecha);
    var mes = fechaObj.getMonth() + 1;

    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth() + 1;

    var mensaje = "";
    if (mes === mesActual) {
      mensaje = "Cumpleañero 🥳📆 " + (fecha ?? "");
    } else {
      mensaje = "Fecha nacimiento: " + (fecha ?? "");
    }
    const textColor = (Cumpleaños === null ? "red" : (mes === mesActual ? "green" : STheme.color.text));
    return <Text style={{ color: textColor }}>{mensaje}</Text>;
  }

  valido_Cumpleaños3(Cumpleaños) {
    var fecha = Cumpleaños;
    var fechaObj = new Date(fecha);
    var mes = fechaObj.getMonth() + 1;

    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth() + 1;

    var mensaje = "";
    if (mes === mesActual) {
      mensaje = "Cumpleañero 🥳📆 " + (fecha ?? "");
    } else {
      mensaje = "Fecha nacimiento: " + (fecha ?? "");
    }
    const textColor = (Cumpleaños === null ? "red" : (mes === mesActual ? "green" : STheme.color.text));
    return <Text style={{ fontSize: 14, color: textColor }}>{mensaje}</Text>;
  }

  valido_Cargo(cargo) {
    // valido si es null el parametro cargo
    return (
      <Text style={{ color: (cargo ?? "").length < 12 ? "red" : STheme.color.text }}>
        {"Cargo: " + (cargo ?? "")}
      </Text>
    );
  }

  valido_Empresa(empresa) {
    // valido si es null el parametro cargo
    return (
      <Text style={{ color: (cargo ?? "").length < 12 ? "red" : STheme.color.text }}>
        {"Cargo: " + (cargo ?? "")}
      </Text>
    );
  }

  valido_Zona(zona) {
    // valido si es null el parametro cargo
    return (
      <Text style={{ color: (zona ?? "").length < 12 ? "red" : STheme.color.text }}>
        {"zona: " + (zona ?? "")}
      </Text>
    );
  }



  pdfBody() {

    return Object.values(finalData).map((obj, i) => {

      return <SPDF.View >
        <SPDF.Text style={{ width: "100%", }}>Nro. {i + 1}</SPDF.Text>;
        <SPDF.Text style={{ width: "100%", }}>{obj.sucursal?.descripcion}</SPDF.Text>;
        <SPDF.Text style={{ width: "100%", }}>{new SDate(obj.data?.fecha_on).toString("yyyy-MM-dd")}</SPDF.Text>;
        <SPDF.Text style={{ width: "100%", }}>cajero {obj.usuario?.Nombres}</SPDF.Text>;
        <SPDF.Text style={{ width: "100%", }}>{obj.paquete?.descripcion}</SPDF.Text>;
        <SPDF.Text style={{ width: "100%", }}>{obj.data?.observacion}</SPDF.Text>;
        <SPDF.Text style={{ width: "100%", }}>{obj.data?.monto ?? "0"}</SPDF.Text>;
        <SPDF.Text style={{ width: "100%", }}>{obj.data?.fecha_inicio}</SPDF.Text>;
        <SPDF.Text style={{ width: "100%", }}>fin {obj.data?.fecha_fin}</SPDF.Text>;
      </SPDF.View>

    })
  }

  imprimir() {
    // if (!this.data) return <SLoad />

    // this.data = Model.usuario.Action.getByKey(this.key);
    // if (!this.data) return <SLoad />
    // console.log("jajajaj ", this.data);

    // this.dataPaquete = Model.PaquetesDeUsuario.Action.getByKey(this.key);
    // if (!this.dataPaquete) return <SLoad />

    return SPDF.create(<SPDF.Page style={{ width: 612, height: 791, margin: 50, padding: 10, }} >
      <SPDF.Text style={{ fontWeight: "bold", fontSize: 25 }}>{"HISTORIAL DE USUARIO"}</SPDF.Text>
      <SPDF.View style={{ width: "100%", height: 8 }}></SPDF.View>
      <SPDF.View style={{
        width: "100%",
        padding: 4,
      }}>
        <SPDF.View style={{
          width: "100%",
          flexDirection: "row",
        }}>
          <SPDF.View >
            <SPDF.Text style={{ width: "100%", }}>{"CALISTENIA SRL"}</SPDF.Text>
            <SPDF.View style={{ width: "100%", height: 4 }}></SPDF.View>
            <SPDF.Text style={{ width: "100%", }}>{"Suc. Ciudad Satelite"}</SPDF.Text>
            <SPDF.View style={{ width: "100%", height: 4 }}></SPDF.View>
            {/* <SPDF.Text style={{ width: "100%", }}>{"P.V. PUESTO DE CAJA 1"}</SPDF.Text> */}



            <SPDF.Text style={{ width: "100%", }}>{this.data["Nombres"] + " " + this.data["Apellidos"]}</SPDF.Text>
            <SPDF.Text style={{ width: "100%", }}>{this.data?.CI}</SPDF.Text>
            <SPDF.Text style={{ width: "100%", }}>{this.data?.Telefono}</SPDF.Text>
            <SPDF.Text style={{ width: "100%", }}>{this.data?.Correo}</SPDF.Text>
            <SPDF.Text style={{ width: "100%", }}>{this.data["Fecha nacimiento"]}</SPDF.Text>
          </SPDF.View>
          <SPDF.View style={{ flex: 1 }}></SPDF.View>
          <SPDF.View style={{ width: 100 }}>
            <SPDF.Text style={{}}>{new SDate().toString("DAY, dd de MONTH del yyyy")}</SPDF.Text>
          </SPDF.View>

        </SPDF.View>
      </SPDF.View>
      <SPDF.View style={{ width: "100%", height: 16 }}></SPDF.View>
      <SPDF.View style={{
        width: "100%",
        borderWidth: 1,
        padding: 4,
      }}>
        <SPDF.View style={{ width: "100%", height: 4 }}></SPDF.View>
        <SPDF.Text style={{ width: "100%", }}>{"Cliente:  ALVARO SILES ESTRADAS"}</SPDF.Text>
        <SPDF.View style={{ width: "100%", height: 4 }}></SPDF.View>
        <SPDF.Text style={{ width: "100%", }}>{"Nit:  1534156"}</SPDF.Text>
        <SPDF.View style={{ width: "100%", height: 4 }}></SPDF.View>
      </SPDF.View>
      <SPDF.View style={{ width: "100%", height: 16 }}></SPDF.View>
      {/* {this.getPlanDePagos()} */}
      {this.pdfBody()}

    </SPDF.Page >)
  }

  imprimir3() {

    const ctfs = (val) => {
      this.count += 1;
      return <SPDF.Text style={{ fontSize: val }}>{this.count}. style.fontSize: {val}</SPDF.Text>
    }

    return SPDF.create(<SPDF.Page style={{ width: 612, height: 791, margin: 20, padding: 20, borderWidth: 1, }} >
      <SPDF.Text style={{ fontWeight: "bold" }}>Prueba de fuentes de letra del componente Text:</SPDF.Text>
      <SPDF.Text >{`<SPDF.Text style={{ fontSize: val }}>{this.count}. style.fontSize: {val}</SPDF.Text>`}</SPDF.Text>
      {ctfs(8)}
      {ctfs(9)}
      {ctfs(10)}
      {ctfs(11)}
      {ctfs(12)}
      {ctfs(13)}
      {ctfs(14)}
      {ctfs(15)}
      {ctfs(16)}
      {ctfs(17)}
      {ctfs(18)}
      {ctfs(19)}
      {ctfs(20)}
      <SPDF.Text style={{
        width: "100%"
      }} >Prueba de texto largo: a dajs dkajs dakjs dkasjd aksjd akdj aksdj akdaj dkajd aksjd aksdja dkaj dakjd akdj aksdj askdasdajdka djka dkjas dajdasdjad aksd jaksdajdkajds akjd akdj asdajdaksdjakdajsdkadkasdjs djs dajd adajsd aksdakjd as</SPDF.Text>
      <SPDF.View style={{
        width: "100%",
        height: 50,
        borderWidth: 1,
        margin: 4,
        alignItems: "center",

      }}>
        <SPDF.Text style={{
          borderWidth: 1,
        }}>Texto dentro de el div</SPDF.Text>
      </SPDF.View>
      <SPDF.View style={{
        width: "100%",
        borderWidth: 1,
        padding: 4,
        margin: 4,
        flexDirection: "row",

      }}>
        <SPDF.Text style={{
          borderWidth: 1,
          flex: 1,
        }}>texto con flex 1</SPDF.Text>
        <SPDF.Text style={{
          borderWidth: 1,
          flex: 1,
        }}>Texto con flex 1</SPDF.Text>
      </SPDF.View>
      <SPDF.View style={{
        width: "100%",
        borderWidth: 1,
        margin: 4,
        flexDirection: "row",
      }}>
        <SPDF.Text style={{
          borderWidth: 1
        }} >Texto1 dentro de el div</SPDF.Text>
        <SPDF.Text style={{
          borderWidth: 1
        }} >Texto2 dentro de el div</SPDF.Text>
        <SPDF.Text style={{
          borderWidth: 1
        }} >Texto3 dentro de el div</SPDF.Text>
        <SPDF.Text style={{
          borderWidth: 1,
          width: 100
        }} >Texto4 dentro de el div</SPDF.Text>
        <SPDF.Text style={{
          borderWidth: 1
        }} >Texto5 dentro de el div</SPDF.Text>
      </SPDF.View>
      <SPDF.Text style={{
        borderWidth: 1,
        fontSize: 20
      }} >Hellow World</SPDF.Text>
    </SPDF.Page>)
  }
  imprimir2() {
    // this.data = Model.usuario.Action.getByKey(this.key);
    // if (!this.data) return <SLoad />
    // console.log("jajajaj ", this.data);
    return SPDF.create(<SPDF.Page style={{ width: 612, height: 791, margin: 50, padding: 10, }} >
      <SPDF.Text style={{ fontWeight: "bold", fontSize: 25 }}>{"RECIBO"}</SPDF.Text>
      <SPDF.View style={{ width: "100%", height: 8 }}></SPDF.View>
      <SPDF.View style={{
        width: "100%",
        padding: 4,
      }}>
        <SPDF.View style={{
          width: "100%",
          flexDirection: "row",
        }}>
          <SPDF.View >
            <SPDF.Text style={{ width: "100%", }}>{"CALISTENIA SRL"}</SPDF.Text>
            <SPDF.View style={{ width: "100%", height: 4 }}></SPDF.View>
            <SPDF.Text style={{ width: "100%", }}>{"Suc. Ciudad Satelite"}</SPDF.Text>
            <SPDF.View style={{ width: "100%", height: 4 }}></SPDF.View>
            <SPDF.Text style={{ width: "100%", }}>{"P.V. PUESTO DE CAJA 1"}</SPDF.Text>
          </SPDF.View>
          <SPDF.View style={{ flex: 1 }}></SPDF.View>
          <SPDF.View style={{ width: 100 }}>
            <SPDF.Text style={{}}>{new SDate().toString("DAY, dd de MONTH del yyyy")}</SPDF.Text>
          </SPDF.View>
        </SPDF.View>
      </SPDF.View>
      <SPDF.View style={{ width: "100%", height: 16 }}></SPDF.View>
      <SPDF.View style={{
        width: "100%",
        borderWidth: 1,
        padding: 4,
      }}>
        <SPDF.View style={{ width: "100%", height: 4 }}></SPDF.View>
        <SPDF.Text style={{ width: "100%", }}>{"Cliente:  ALVARO SILES ESTRADAS"}</SPDF.Text>
        <SPDF.View style={{ width: "100%", height: 4 }}></SPDF.View>
        <SPDF.Text style={{ width: "100%", }}>{"Nit:  1534156"}</SPDF.Text>
        <SPDF.View style={{ width: "100%", height: 4 }}></SPDF.View>
      </SPDF.View>
      <SPDF.View style={{ width: "100%", height: 16 }}></SPDF.View>
      {/* {this.getPlanDePagos()} */}
    </SPDF.Page >)
  }


  getPerfil() {
    this.data = Model.usuario.Action.getByKey(this.key);
    if (!this.data) return <SLoad />
    return <SView center col={"xs-11 md-8 lg-6"} style={{ width: "100%", justifyContent: "center", alignItems: "center", backgroundColor: STheme.color.card, borderRadius: 8 }} row border>
      <SView col={"xs-12"} row height center  >
        <SHr height={24} />

        <SView col={"xs-12 md-4"} border={"transparent"} center >
          <SView border="transparent" style={{ width: 180, height: 180, }} center>
            <FotoPerfilUsuario usuario={this.data} />
          </SView>
        </SView>
        <SView col={"xs-12 md-8"} border={"transparent"} center>
          <SView col={"xs-12"}>
            <SText fontSize={16} style={{ color: STheme.color.text }}>Datos de usuario</SText>
            <SHr height={4} />
            {this.valido_NombreCompleto(this.data?.Nombres, this.data?.Apellidos)}
            {this.valido_CI(this.data?.CI)}
            {this.valido_Telefono(this.data?.Telefono)}
            {this.valido_Cumpleaños(this.data["Fecha nacimiento"])}
            {this.valido_Sexo(this.data?.Sexo)}
            {this.valido_Festivo(this.data?.Festivo)}
            {this.valido_Correo(this.data?.Correo)}
            <SHr />
            <SText fontSize={16} style={{ color: STheme.color.text }}>Datos para la facturación</SText>
            <SHr height={4} />
            {this.valido_Nit(this.data?.Nit)}
            <SHr height={4} />
            {this.valido_RazonSocial(this.data?.RazonSocial)}
          </SView>
        </SView>

        {/* <SHr height={20} /> */}

        <SHr height={24} />

        <SView col={"xs-12"} row center border={"transparent"}>

          <SView col={"xs-12 md-4"} border={"transparent"} center  >
            <SView col={"xs-11 md-11 lg-9"}>
              <Botoncito padding={8} onPress={() => { SNavigation.navigate("Huellas", { key: this.key }) }} row><SIcon name='Fp' fill={STheme.color.lightGray} width={30} />{"Editar huella"}</Botoncito>
            </SView>
          </SView>

          <SView col={"xs-12 md-4"} border={"transparent"} center   >
            <SView col={"xs-11 md-11 lg-9"}>
              <Botoncito padding={8} onPress={() => {
                SNavigation.navigate("/documentos", { key_usuario: this.key })
                // alert("estamos trabajando")
              }} row><SIcon name='Fp' fill={STheme.color.lightGray} width={30} />Documentos</Botoncito>
            </SView>
          </SView>


          <SView col={"xs-12 md-4"} border={"transparent"} center>
            <SView col={"xs-11 md-12 lg-9"}>
              {this.getBtnVerAsistencias()}
            </SView>
          </SView>
        </SView>

        <SHr height={24} />

      </SView>



      {this.EditarPerfil()}


    </SView >
  }

  cargarDatos({ force }) {
    var reducer = this.props.state.paqueteVentaReducer;
    var data = reducer.usuario[this.key];
    if (!data) {
      if (reducer.estado == "cargando") return null;
      if (reducer.estado == "error") return <Text>ERROR</Text>
      var object = {
        component: "paqueteVenta",
        type: "getAllByUsuario",
        estado: "cargando",
        key_usuario: this.key
      }
      SSocket.send(object, true);
      // return <View />
    }

    var objFinal = {};

    return new SOrdenador([
      { key: "fecha_inicio", order: "desc", peso: 1 },
    ]).ordernarObject(data).map((key) => {

      var obj = data[key];

      if (!sucursal_usuario.Actions.isActive(obj.key_sucursal, this.props)) return null;

      var usuarios = Model.usuario.Action.getAll();
      if (!usuarios) return <SLoad />
      // var usuario = Object.values(usuarios).find(aux => aux.key == obj.key_usuario);
      var usuario = usuarios[obj.key_usuario];

      var paquetes = Paquete.Actions.getAll(this.props);
      if (!paquetes) return null;
      // var paquete = Object.values(paquetes).find(aux => aux.key == obj.key_paquete);
      var paquete = paquetes[obj.key_paquete];

      var sucursales = Sucursal.Actions.getAll(this.props);
      if (!sucursales) return null;
      // var sucursal = Object.values(sucursales).find(aux => aux.key == obj.key_sucursal);
      var sucursal = sucursales[obj.key_sucursal];

      // console.log("aqui ", obj, " pack ", paquete.descripcion, " sucursal ", sucursal.descripcion, " usuario ", usuario.Nombres);

      // objFinal[key] = { obj, usuario, paquete, sucursal }
      objFinal[key] = {
        data: obj,
        usuario: usuario,
        paquete: paquete,
        sucursal: sucursal,
      };
      finalData = objFinal;

    })

  }


  template() {
    return <>
      <SView flex border={"yellow"}
        style={{ position: "absolute", top: 80, borderRadius: 8, padding: 8, fontSize: 2, left: 12 }}>
        <SText bold color={"yellow"}>Nota:</SText>
        <SHr></SHr>
        <SText>La facturación se integró</SText>
        <SText>el 22 de febrero de 2024</SText>
      </SView>
    </>
  }


  mensaje2() {
    return <>
      <SView width={200} flex border={"cyan"}
        style={{ position: "absolute", top: 80, borderRadius: 8, padding: 8, right: 12 }}>
        <SText bold color={"cyan"}>BOTÓN FACTURAR:</SText>
        <SHr></SHr>
        <SText>Solo permite facturar dentro del mes en curso.</SText>
      </SView>
    </>
  }

  mensaje3() {
    return <>
      <SView width={200} flex border={"red"}
        style={{ position: "absolute", top: 180, borderRadius: 8, padding: 8, right: 12 }}>
        <SText bold color={"red"}>BOTÓN ANULAR FACTURA:</SText>
        <SHr></SHr>
        <SText>Solo permite anular facturas emitidas dentro del mes.</SText>
      </SView>
    </>
  }


  templateCupleaños() {
    return <>
      <SView col={"xs-2.5 sm-1 md-3.5  "} center border={"transparent"} style={{ position: "absolute", top: 35, left: 0, }}><SIcon name="HbHeaderLeft1" style={{ width: "100%" }} /></SView>
      <SView col={"xs-2.5 sm-1 md-3"} center border={"transparent"} style={{ position: "absolute", top: 35, right: 0, }}><SIcon name="HbHeaderRight1" style={{ width: "100%" }} /></SView>
      <SView col={"xs-2.5 sm-1 md-2  "} center style={{ position: "absolute", bottom: 0, left: 0, }}><SIcon name="HbFooterLeft1" style={{ width: "100%" }} /></SView>
      <SView col={"xs-2.5 sm-1 md-2  "} center style={{ position: "absolute", bottom: 0, right: 0, }}><SIcon name="HbFooterRight1" style={{ width: "100%" }} /></SView>
    </>
  }

  responsables() {
    if (!this.key) return;
    return <>
      <SView flex border={STheme.color.card} style={{ position: "absolute", top: 0, left: '5%', padding: 2 }}>
        {this.persona()}
      </SView>
    </>
  }

  persona() {
    var usuarios = Model.usuario.Action.getAll();
    if (!usuarios) return <SLoad />

    let datos = this.state?.data_usuario;
    if (!datos) return <SLoad />


    return Object.keys(datos).map(key => {
      let obj = datos[key];
      let fullname = usuarios[obj?.key_usuario]?.Nombres + " " + usuarios[obj?.key_usuario]?.Apellidos;
      return (
        <>
          <SText fontSize={13}>tipo: {obj?.tipo}</SText>
          {!obj?.key_usuario ? null : <SText fontSize={13}>{fullname}</SText>}
          <SText fontSize={13}>{new SDate(obj?.fecha_on).toString('yyyy-MM-dd hh:mm')}</SText>
          <SHr height={8} />
        </>
      );
    });
  }

  exportar_excel() {

    if (!SSRolesPermisosValidate({ page: "ClientesPage", permiso: "exportar_excel" })) {
      return;
    }

    return <ExportExcel
      header={[
        { key: "indice", label: "Indice", width: 30 },
        // { key: "cliente", label: "cliente", width: 40 },
        { key: "sucursal", label: "sucursal", width: 50 },
        { key: "fecha_on", label: "fecha compra", width: 80 },
        { key: "cajera", label: "Cajera", width: 100 },
        { key: "paquete", label: "paquete", width: 140 },
        { key: "motivo", label: "motivo", width: 100 },
        { key: "precio", label: "precio", width: 40 },
        { key: "fecha_inicio", label: "paquete inicio", width: 80 },
        { key: "fecha_fin", label: "paquete fin", width: 80 },
      ]}
      getDataProcesada={() => {
        var daFinal = {};
        Object.values(finalData).map((obj, i) => {
          // if (!obj.data.estado || obj.data.estado <= 0) return;
          var toInsert = {
            indice: i + 1,
            sucursal: obj.sucursal?.descripcion,
            fecha_on: new SDate(obj.data?.fecha_on).toString("yyyy-MM-dd"),
            cajera: obj.usuario?.Nombres,
            paquete: obj.paquete?.descripcion,
            motivo: obj.data?.observacion,
            precio: obj.data?.monto ?? "0",
            fecha_inicio: obj.data?.fecha_inicio,
            fecha_fin: obj.data?.fecha_fin,
          }
          daFinal[i] = toInsert
        })
        return daFinal
      }}
    />
  }




  getBtnVerAsistencias() {
    if (!SSRolesPermisosValidate({ page: "PaqueteVentaPage", permiso: "editar_venta" })) return;
    return <Botoncito padding={8} onPress={() => { SNavigation.navigate("asistencias_usuario", { key: this.key }) }} row ><SIcon name='Fp' fill={STheme.color.lightGray} width={30} />ASISTENCIAS</Botoncito>
  }



  render() {
    this.cargarDatos({});
    return (<>

      <SPage hidden >
        <BarraSuperior duration={500} title={"Perfil de cliente"} navigation={this.props.navigation} goBack={() => {
          SNavigation.goBack()
        }} {...this.props} />
        <ScrollView>
          <SView col={"xs-12"} center>
            {/* {this.responsables()} */}
            {this.getPerfil()}
            <SView col={"xs-11 md-8 xl-6"} center>
              <PaquetesDeUsuarioV2 key_usuario={this.key} navigation={this.props.navigation} usuario={this.data} />
            </SView>
          </SView>
        </ScrollView>
      </SPage >
      {this.template()}
      {this.mensaje2()}
      {this.mensaje3()}

    </>);
  }
}

const initStates = (state) => {
  return { state }
};
export default connect(initStates)(ClientePerfilPage);