import { Component } from "react";
import { Linking } from "react-native";
import { connect } from "react-redux";
import { SDate, SLoad, SNavigation, SPage, SPopup, STable2, SText, SView } from "servisofts-component";
import SSocket from "servisofts-socket";
import Botoncito from "../../Components/Botoncito";
import Model from "../../Model";
import sucursal_usuario from "../sucursal_usuario";

class consolidados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Export Base Datos",
      func: "_facturas_consolidados",
      ...this.state,
    };
    this.fecha_inicio = SNavigation.getParam("fecha_inicio");
    this.fecha_fin = SNavigation.getParam("fecha_fin");
  }

  componentDidMount() {
    this.validarFechas();
    this.getData();
    Model.usuario.Action.getAll({ force: true });
  }

  validarFechas() {
    if (!this.fecha_inicio || !this.fecha_fin) {
      alert("Problemas con los parámetros de fecha_inicio y fecha_fin.");
      SNavigation.navigate("/facturacion"); // Vuelve a la página anterior si hay problemas
    }
  }

  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: this.state.func,
      params: ["'" + this.fecha_inicio + "'", "'" + this.fecha_fin + "'"],
      ...this.params
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }

  getAll() {
    var dase_datos = this.state.data;
    if (!dase_datos) return (<>
      <SText>Cargando facturas...</SText>
      <SLoad />;
    </>);

    var usuarios = Model.usuario.Action.getAll();
    if (!usuarios) return (<>
      <SText>Cargando servidor usuarios...</SText>
      <SLoad />;
    </>);

    var aux = sucursal_usuario.Actions.getActive(this.props);
    if (!aux) return (<>
      <SText>Cargando sucursal_usuario...</SText>
      <SLoad />;
    </>);


    return (
      <STable2
        limit={15}
        data={dase_datos}
        cellStyle={{ fontSize: 12 }}
        header={[
          { key: "index", label: "ÍNDICE", width: 50 },
          {
            key: "sucursal_descripcion", label: "SUCURSAL", width: 110,
            component: (e) => <SView ><SText>{`${e}`}</SText> </ SView>
          },
          {
            key: "fecha_factura", label: "FECHA EMISIÓN📅", width: 110, center: true, render: (item) => {
              if (!item) return "";
              return new SDate(item).toString("yyyy-MM-dd");
            }, component: (e) => <SView ><SText>{`${e}`}</SText> </ SView>
          },
          {
            key: "-fecha_factura", label: "HORA EMISIÓN📅", width: 100, center: true, render: (item) => {
              if (!item.fecha_factura) return "";
              return new SDate(item.fecha_factura).toString("hh:mm");
            }, component: (e) => <SView ><SText>{`${e}`}</SText> </ SView>
          },
          {
            key: "codigo_factura", label: "CÓDIGO FACTURA⚡", order: "desc", center: true, width: 120
          },
          { key: "nit", label: "NIT", width: 80 },
          { key: "razon_social", label: "RAZÓN SOCIAL", width: 180 },

          {
            key: "key_usuario_factura", label: "FACTURADOR", width: 140, render: (item) => {
              if (!item) return "-";
              return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}`;
            },
            component: (e) => {
              return <SView> <SText>{`${e}`}</SText> </ SView >
            }
          },


          {
            key: "-onpress98", renderExcel: (a) => "", label: "DESCARGAR", width: 100, component: obj => {
              return <Botoncito height={20} onPress={() => {
                console.log(obj)

                SSocket.sendPromise({
                  component: "facturacion",
                  type: "getFacturaPdfV2",
                  codigo_factura: obj?.codigo_factura + ""
                }).then(e => {
                  const byteCharacters = atob(e.data);
                  const byteNumbers = new Array(byteCharacters.length);
                  for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);
                  const blob = new Blob([byteArray], { type: 'application/pdf' });
                  const blobUrl = URL.createObjectURL(blob);
                  window.open(blobUrl, '_blank');
                  console.log(e);
                }).catch(e => {
                  console.error(e);
                })
              }} >{"Factura"}</Botoncito>
            }
          },

          {
            key: "-onpress", label: "FACTURA", width: 100,
            renderExcel: (a) => "-",
            component: obj => {
              if (obj?.codigo_factura + "") {
                return <Botoncito
                  height={20}
                  style={{ backgroundColor: "#E30613" }}
                  onPress={() => {
                    SSocket.sendPromise({
                      component: "facturacion",
                      type: "anularFactura",
                      codigo_factura: obj?.codigo_factura + "",
                    }).then(e => {
                      alert("La factura ha sido anulada con éxito. ¡Operación completada satisfactoriamente!");
                      console.log(e);
                      SSocket.sendPromise({
                        component: "paqueteVenta",
                        type: "anularFactura",
                        codigo_factura: obj?.codigo_factura + "",
                      }).then(e => {
                        alert("Campos borrados código de factura, NIT y razón social!");
                        console.log(e);
                      }).catch(e => {
                        console.error(e);
                        alert(e.error);
                        SPopup.alert("Error anular al factura.");
                      })
                    }).catch(e => {
                      console.error(e);
                      SPopup.alert("Error anular al factura.");
                    })
                  }}
                >Anular</Botoncito>
              }
              return <Botoncito height={20} onPress={() => {
                if (obj?.codigo_factura + "") {
                  SPopup.alert("Ya fue facturado");
                  return;
                }
                SSocket.sendPromise({
                  component: "facturacion",
                  type: "facturarPaquete",
                  key_paquete_venta: obj?.codigo_factura + "",
                }).then(e => {
                  console.log(e);
                }).catch(e => {
                  console.error(e);

                  if (e?.error) {
                    if (e?.error.indexOf("fac_codigo_producto") > -1) {
                      SPopup.alert("Error: El paquete no tiene configurado el codigo de producto ni la unidad de medida.")
                      return;
                    }
                    SPopup.alert(e.error);
                    return;
                  }
                  SPopup.alert("Error desconocido al generar factura.");

                })
              }} >{"FACTURA"}</Botoncito>
            }
          },



          {
            key: "paquete_venta_fecha_on", label: "FECHA COMPRA", width: 100, center: true, render: (item) => { return new SDate(item).toString("yyyy - MM - dd"); }, component: (e) => <SView ><SText>{`${e}`}</SText> </ SView>
          },
          { key: "paquete", label: "PAQUETE", width: 150, component: (e) => <SView ><SText>{`${e}`}</SText> </ SView> },
          { key: "paquete_dias", label: "DÍAS", center: true, width: 40 },
          { key: "paquete_precio", label: "PRECIO", center: true, width: 50 },
          {
            key: "participantes", label: "PARTICIPANTES", center: true, width: 90
          },
          {
            key: "clientes", label: "CLIENTES", width: 220, render: (item) => {
              let txt = "";
              if (Array.isArray(item)) {
                item.map((obj) => {
                  txt += (usuarios[obj]?.Nombres + " " + usuarios[obj]?.Apellidos ?? "");
                  txt += ((item.length > 1) ? ", " : "");
                })
              }
              return (txt).toUpperCase();
            }
          },
          { key: "pagado", label: "PAGADO", center: true, sumar: true, width: 80 },


          {
            key: "-onpress96e458", renderExcel: (a) => "", label: "DESCARGAR", width: 100, component: obj => {
              return <SView border="yellow" center style={{ borderRadius: 2, width: 100, padding: 2 }}
                onPress={() => {
                  Linking.openURL(`/EsperandoVenta?key=${obj.key_paquete_venta}&key_paquete_venta_usuario=${obj.key_paquete_venta_usuario}&t=1`)
                }}
              >
                <SText fontSize={13} color={"yellow"}>VER RECIBO</SText>
              </SView>
            }
          },

          {
            key: "-onpress964588656", renderExcel: (a) => "", label: "PERFIL", width: 100, component: obj => {
              return <SView border="yellow" center style={{ borderRadius: 2, width: 100, padding: 2 }}
                onPress={() => {
                  Linking.openURL(`/ClientePerfilPage?key=` + obj.primer_cliente)
                }}
              >
                <SText fontSize={13} color={"yellow"}>VER PERFIL</SText>
              </SView>
            }
          },

          {
            key: "cajero_key", label: "ATC", width: 180, render: (item) => { return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}`; },
            component: (e) => <SView ><SText>{`${e}`}</SText> </ SView>
          },

          { key: "tipos_pago_descripcion", label: "TIPO PAGO", width: 90, component: (e) => <SView ><SText color={"cyan"} >{`${e}`}</SText> </ SView> },
        ]
        }
        filter={(item) => {
          if (!item.codigo_factura) return false;
          return sucursal_usuario.Actions.isActive(
            item.sucursal_key,
            this.props
          );
        }}

      />
    );
  }
  render() {
    return (
      <SPage title={"REPORTE DETALLADO DE FACTURAS"} disableScroll center>
        {this.getAll()}
      </SPage>
    );
  }
}
const initStates = (state) => { return { state }; };
export default connect(initStates)(consolidados);
