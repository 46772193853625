import { SPage } from "servisofts-component";

// PAGES OLD //
import Client from "../Client";
import Services from "../Services";
import AjustesPage from "./AjustesPage";
import Asistencia from "./Asistencia";
import Banco from "./Banco";
import Billetera from "./Billetera";
import Caja from "./Caja";
import CargaPage from "./CargaPage/index";
import clientes_activos from "./clientes_activos";
import DashBoard from "./DashBoard";
import Entrenamiento from "./Entrenamiento";
import Finanza from "./Finanza";
import Inversiones from "./Inversiones";
import Manual from "./Manual";
import Paquete from "./Paquete";
import PaqueteVenta from "./PaqueteVenta";
import Presentacion from "./Presentacion";
import prorroga from "./prorroga";
import Servicio from "./Servicio";
import Sucursal from "./Sucursal";
import Test from "./Test/Test";
import TipoPago from "./TipoPago";
import Usuario from "./Usuario";
// PAGES NEW//
import contabilidad from "./contabilidad";
import empresa from "./empresa";
import rol from "./rol";
import root from "./root";
import sucursal_paquete_venta_usuario from "./sucursal_paquete_venta_usuario";
import testAlvaro from "./testAlvaro";
import testTablaUsuarios from "./testTablaUsuarios";

import activos from "./activos";
import asistencias_usuario from "./asistencias_usuario";
import baseDatosAsistencias from "./baseDatosAsistencias";
import baseDatosVentas from "./baseDatosVentas";
import caja_en_cero from "./caja_en_cero";
import documentos from "./documentos";
import facturacion from "./facturacion"; //Agregado por ricky el 02-02-2024 ( Requerido para la facturacion )
import fidelizacion from "./fidelizacion";
import grupo_ventas from "./grupo_ventas";
import historial_ventas_elimanadas from "./historial_ventas_elimanadas";
import historial_ventas_modificadas from "./historial_ventas_modificadas";
import migrador from "./migrador";
import reporte_vany from "./reporte_vany";
import responsabilidades from "./responsabilidades";
import servicio2 from "./servicio2";
import sql from "./sql/index";
import terminos from "./terminos";
import testAlvaroHorario from "./testAlvaroHorario";
import testAlvaroMail from "./testAlvaroMailNoSirve";
import testAlvaroski from "./testAlvaroski";
import ubicacion_gps from "./ubicacion_gps";
import wellcome_molinete from "./wellcome_molinete";
const newPages = SPage.combinePages("/", {
  "": root,
  sql,
  test: Test,
  documentos: documentos,
  ubicacion_gps: ubicacion_gps,
  testAlvaroski: testAlvaroski,
  terminos: terminos,
  ...empresa,
  ...contabilidad,
  ...fidelizacion,
  ...grupo_ventas,
  ...responsabilidades,
  ...rol,
  ...sucursal_paquete_venta_usuario,
  ...activos,
  ...servicio2,
  // ...baseDatosAsistencias,
  ...migrador,
  ...facturacion, //Agregado por ricky el 02-02-2024 ( Requerido para la facturacion )
});

export default {
  ...newPages,
  // "inicio": InicioPage,
  inicio: root,
  carga: CargaPage,
  presentacion: Presentacion,
  clientes_activos: clientes_activos,
  AjustesPage,
  ...Usuario.Pages,
  ...Sucursal.Pages,
  // ...SSRolesPermisosPages,
  ...TipoPago.Pages,
  ...Paquete.Pages,
  ...Servicio.Pages,
  ...Banco.Pages,
  ...Caja.Pages,
  ...PaqueteVenta.Pages,
  ...Entrenamiento.Pages,
  ...Asistencia.Pages,
  ...Finanza.Pages,
  ...DashBoard.Pages,
  ...Manual.Pages,
  ...Billetera.Pages,
  ...prorroga.Pages,
  ...Inversiones.Pages,
  ...Services.Pages,
  ...Client.Pages,
  alvaro: testAlvaro,
  bd_asistencias: baseDatosAsistencias,
  bd_ventas: baseDatosVentas,
  tabla: testTablaUsuarios,
  mail: testAlvaroMail,
  horario: testAlvaroHorario,
  caja_en_cero: caja_en_cero,
  reporte_vany: reporte_vany,
  asistencias_usuario: asistencias_usuario,
  wellcome_molinete: wellcome_molinete,
  historial_ventas_elimanadas: historial_ventas_elimanadas,
  historial_ventas_modificadas: historial_ventas_modificadas,
  // activos: activos,
};
