import { Component } from "react";
import { Linking } from "react-native";
import { connect } from "react-redux";
import {
  SButtom,
  SDate,
  SForm,
  SHr,
  SIcon,
  SLoad,
  SMapView,
  SNavigation,
  SPage,
  SPopup,
  SText,
  STheme,
  SView
} from "servisofts-component";
import SSocket from "servisofts-socket";
import Botoncito from "../../../Components/Botoncito";
import FotoDocumentoUsuario from "../../../Components/FotoDocumentoUsuario";
import FotoPerfilUsuario from "../../../Components/FotoPerfilUsuario";
import Model from "../../../Model";
import { SSRolesPermisosValidate } from "../../../SSRolesPermisos";
import Sucursal_usuario from "../../sucursal_usuario";
import RolDeUsuario from "./RolDeUsuario";

let _var_color = "cyan";
class RegistroCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: {
        latitude: -17.7833276,
        longitude: -63.1821408,
      }
    };
    this.key = SNavigation.getParam("key");
    this.key_rol = SNavigation.getParam("key_rol");
    if (!this.key_rol) {
      this.key_rol = "d16d800e-5b8d-48ae-8fcb-99392abdf61f";
    }
  }

  componentDidMount() {
    if (this.key) {
      this.getData();
    }
  }

  getData() {
    // console.log("'" + this.state.parametros.inicio + "'", "'" + this.state.parametros.fin + "'");
    // retur
    this.setState({ loading: "cargando", data_usuario: null });
    SSocket.sendPromise({
      version: "2.0",
      component: "usuario",
      type: "get_last_history_by_key_usuario",
      service: "usuario",
      key_usuario: this.key,
    })
      .then((resp) => {
        this.setState({ loading: false, data_usuario: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }

  render_foto() {
    this.data = Model.usuario.Action.getByKey(this.key);
    if (!this.data) return;
    return <>
      {/* <SHr /> */}
      <SView col={"xs-12  "} border={"transparent"} center row   >
        <SView col={"xs-5"} border={STheme.color.card} center >
          <SView style={{ width: 180, height: 180 }} center>
            <FotoPerfilUsuario usuario={this.data} />
          </SView>
        </SView>

        <SView col={"xs-7 "} border={"transparent"} center height >

          <SView col={"xs-11 md-11 lg-10"}>
            <Botoncito padding={8} onPress={() => { SNavigation.navigate("Huellas", { key: this.key }) }} row>
              <SIcon name='Fp' fill={STheme.color.lightGray} width={30} />{"Editar huellas"}
            </Botoncito>
          </SView>

          <SHr />
          <SView col={"xs-11 md-11 lg-10"} border={"transparent"}>
            <Botoncito padding={8} onPress={() => {
              // alert("estamos trabajando")
              SNavigation.navigate("/documentos", { key_usuario: this.key })
            }} row>
              <SIcon name='Fp' fill={STheme.color.lightGray} width={30} />{"Documentos"}
            </Botoncito>
          </SView>
          <SHr />
          <SView col={"xs-11 md-11 lg-10"}>
            <Botoncito padding={8} onPress={() => { SNavigation.navigate("/ubicacion_gps", { key_usuario: this.key }) }} row>
              <SIcon name='Fp' fill={STheme.color.lightGray} width={30} />{"Ubicación GPS"}
            </Botoncito>
          </SView>
          <SHr />
          <SView col={"xs-11 md-11 lg-10"} >
            <Botoncito padding={8} onPress={() => { SNavigation.navigate("ClientePerfilPage", { key: this.key }) }} row style={{ borderColor: "yellow", color: "yellow" }} color={"cyan"}  >
              <SIcon name='Fp' fill={STheme.color.lightGray} width={30} />{"Historial Ventas"}
            </Botoncito>
          </SView>
        </SView>

      </SView>

    </>
  }
  render_foto_documento() {
    this.data = Model.usuario.Action.getByKey(this.key);
    if (!this.data) return <SLoad />
    return <>

      <SView style={{
        width: 180,
        height: 180,
      }} center>
        <FotoDocumentoUsuario usuario={this.data} />
      </SView>
    </>
  }

  render_form() {
    var usr = {};
    if (this.key) {
      usr = Model.usuario.Action.getByKey(this.key);
      if (!usr) return <SLoad />;
    }
    return (
      <SForm
        ref={(ref) => (this.form = ref)}
        row
        style={{
          justifyContent: "space-between",
        }}
        inputProps={{
          col: "xs-12",
          customStyle: "calistenia"
        }}
        inputs={{
          CI: {
            label: "CI",
            // isRequired: true,
            defaultValue: usr.CI,
            col: "xs-12",
            style: { borderColor: _var_color, borderRadius: 8, }
          },
          Nombres: {
            label: "Nombres",
            // isRequired: true,
            defaultValue: usr.Nombres,
            col: "xs-5.9",
            style: { borderColor: _var_color, borderRadius: 8, }
          },
          Apellidos: {
            label: "Apellidos",
            // isRequired: true,
            defaultValue: usr.Apellidos,
            col: "xs-5.9",
            style: { borderColor: _var_color, borderRadius: 8, }
          },
          Sexo: {
            label: "Género",
            type: "select",
            options: [
              { key: "Maculino", content: "Maculino" },
              { key: "Femenimo", content: "Femenimo" },
            ],
            defaultValue: usr.Sexo,
            col: "xs-2.8",
            style: { borderColor: _var_color, borderRadius: 8, }
          },
          "Fecha nacimiento": {
            label: "Fecha de nacimiento",
            type: "date",
            defaultValue: usr["Fecha nacimiento"],
            col: "xs-3.8",
            style: {
              borderColor: _var_color, borderRadius: 8,
            }
          },
          Telefono: {
            label: "Teléfono",
            type: "phone",
            defaultValue: usr.Telefono,
            col: "xs-5",
            style: { borderColor: _var_color, borderRadius: 8, }
          },

          Subordinado: {
            label: "Subordinado",
            type: "select",
            options: [
              { key: "padre", content: "Papá" },
              { key: "madre", content: "Mamá" },
              { key: "Tio", content: "Ti@" },
              { key: "otro", content: "Otro" },
            ],
            defaultValue: usr.Subordinado,
            col: "xs-2.8",
          },
          SubNombre: {
            label: "Nombre completo",
            defaultValue: usr.SubNombre,
            col: "xs-3.8",
          },
          Contacto: {
            label: "Contacto",
            type: "phone",
            defaultValue: usr.Contacto,
            col: "xs-5",
          },

          Direccion: {
            label: "Dirección",
            defaultValue: usr.Direccion,
            col: "xs-9",
          },
          Zona: {
            label: "Zona",
            defaultValue: usr.Zona,
            col: "xs-2.8",
          },
          // "Estado civil": {
          //   label: "Estado civil",
          //   type: "select",
          //   defaultValue: usr["Estado civil"],
          //   options: [
          //     { key: "Soltero", content: "Soltero/a" },
          //     { key: "Casado", content: "Casado/a" },
          //     { key: "Divorciado ", content: "Divorciado/a" },
          //     { key: "Viudo", content: "Viudo/a" },
          //     { key: "otros", content: "Otros" },
          //   ],
          //   // isRequired: true,
          //   col: "xs-4",
          // },
          Festivo: {
            label: "Festivo Papá/Mamá",
            type: "select",
            // isRequired: true,
            options: [
              { key: "padre", content: "Papá" },
              { key: "madre", content: "Mamá" },
              { key: "ninguno", content: "Ninguno" },
            ],
            defaultValue: usr.Festivo,
            col: "xs-4",
            style: { borderColor: _var_color, borderRadius: 8 }
          },
          // Empresa: {
          //   label: "Empresa/Trabajo",
          //   defaultValue: usr.Empresa,
          //   col: "xs-4",
          // },
          // Cargo: {
          //   label: "Cargo",
          //   // isRequired: true,
          //   defaultValue: usr.Cargo,
          //   col: "xs-3",
          // },
          // Referencia: {
          //   label: "Referencia Telf.",
          //   defaultValue: usr.Referencia,
          //   col: "xs-4",
          // },
          Correo: {
            label: "Correo",
            type: "email",
            // isRequired: true,
            defaultValue: usr.Correo,
            style: { borderColor: _var_color }
          },
          Password: {
            label: "Password",
            type: "password",
            isRequired: true,
            defaultValue: usr.Password,
            style: { borderColor: _var_color }
          },
          Nit: {
            label: "Nit",
            type: "number",
            defaultValue: usr.Nit,
            col: "xs-5",
            style: { borderColor: _var_color, borderRadius: 8, }
          },
          RazonSocial: {
            label: "Razón Social",
            defaultValue: usr.RazonSocial,
            col: "xs-6.8",
            style: { borderColor: _var_color, borderRadius: 8, }
          }
        }}
        onSubmit={(values) => {
          // this.setState({ loading: true });
          console.log("Antes de modificar", values);

          // Limpieza de campos eliminando espacios
          for (let clave in values) {
            if (typeof values[clave] === 'string') {
              values[clave] = values[clave].trim();
            }
          }

          // Validación de campos
          const camposValidos = {
            CI: { min: 5, mensaje: "Error en campo CI, menor 5 dígitos" },
            Nombres: { min: 3, mensaje: "Error en campo Nombre" },
            Apellidos: { min: 3, mensaje: "Error en campo Apellidos" },
            Sexo: { min: 3, mensaje: "Error en campo Género" },
            // "Fecha nacimiento": { min: 3, mensaje: "Error en campo Fecha de nacimiento" },
            Telefono: { min: 8, mensaje: "Error en campo teléfono, menor 8 dígitos" },
            Festivo: { min: 3, mensaje: "Error en campo Festivo" },
            Correo: { min: 3, mensaje: "Error en campo Correo" },
            Password: { min: 3, mensaje: "Error en campo Password" },
            Nit: { min: 5, mensaje: "Error en campo NIT, menor 5 dígitos" },
            RazonSocial: { min: 3, mensaje: "Error en campo de Razón Social❌" }
          };

          for (let campo in camposValidos) {
            if ((values[campo]).length < camposValidos[campo].min) {
              // console.log("que paso aqui ", camposValidos[campo].mensaje)
              alert(camposValidos[campo].mensaje); // informando el error
              this.setState({ loading: false }); //quito cargando
              return; //retorno si no cumple
            }
          }

          if (this.key) {
            Model.usuario.Action.editar({
              key_usuario: Model.usuario.Action.getKey(),
              data: {
                ...usr,
                ...values,
                Latitude: this.state.region.latitude,
                Longitude: this.state.region.longitude,
              },
            })
              .then((resp) => {
                console.log("Editó: ", values)
                this.setState({ loading: false });
                alert("¡Actualización exitosa! ✅🎉");
                // TODO: DEBO validar el telefono y correo, si existe en la bd, para que me salga el mensaje que telefono ya existe o el correo
                SNavigation.goBack();
              })
              .catch((e) => {
                console.log("error al editar ", e)
                this.setState({ loading: false });
                alert("¡La actualización ha fallado! Por favor, inténtalo nuevamente. ❌😔");
                // SPopup.alert("Error en al editar el dato " + e.error_dato);
              });
          } else {

            Model.usuario.Action.registro({
              key_usuario: Model.usuario.Action.getKey(),
              data: values,
              key_rol: this.key_rol,
            })
              .then((resp) => {
                console.log("si registro ", values)
                this.setState({ loading: false });
                alert("¡Registro exitoso! ✅🎉");
                // SNavigation.goBack();
              })
              .catch((e) => {
                this.setState({ loading: false });
                alert("¡El registro ha fallado! Por favor, inténtalo nuevamente. ❌😔");
                // SPopup.alert("Error en el registro, " + e.error_dato);
              });
          }
        }}
      />
    );
  }
  render_mapa() {
    var usr = {};
    if (this.key) {
      usr = Model.usuario.Action.getByKey(this.key);
      if (!usr) return <SLoad />;
    }
    return <>
      <SView col={"xs-12"} border="transparent" >

        <SText fontSize={16} color={STheme.color.secondary} bold >UBICACIÓN GPS</SText>
        <SHr height={10}></SHr>

        <SView col={"xs-12"} height={250} border="transparent" style={{ borderRadius: 10 }}>
          <SMapView
            initialRegion={{
              latitude: parseFloat(usr.Latitude) ? parseFloat(usr.Latitude) : this.state.region.latitude,
              longitude: parseFloat(usr.Longitude) ? parseFloat(usr.Longitude) : this.state.region.longitude,
              latitudeDelta: 0.0922,
              longitudeDelta: 0.0421,
            }}
            options={{
              fullscreenControl: false,
              zoomControl: false,
              gestureHandling: "none",
              scrollwheel: false,
            }} preventCenter>
            <></>
            <SMapView.SMarker latitude={parseFloat(usr.Latitude) ? parseFloat(usr.Latitude) : ""} longitude={parseFloat(usr.Longitude) ? parseFloat(usr.Longitude) : ""}>
            </SMapView.SMarker>
          </SMapView>
          <SView col={"xs-12"} height style={{ position: "absolute" }} withoutFeedback></SView>
        </SView>

        <SHr height={20}></SHr>

        <SView col={"xs-12"} row center>
          <Botoncito backgroundColor="#0C0C0C" style={{ borderColor: "black" }} center col={"xs-5.8"} onPress={() => { Linking.openURL(`http://maps.google.com/maps?q=${parseFloat(usr.Latitude) ?? this.state.region.latitude}+${parseFloat(usr.Longitude) ?? this.state.region.longitude}`); }} >IR A GOOGLE MAPS</Botoncito>
          <SView flex></SView>
          <Botoncito backgroundColor="#D70000" style={{ borderColor: "#BF0707" }} center col={"xs-5.8"} onPress={() => { SNavigation.navigate("Mapa", { key: this.key }) }} >EDITAR UBICACIÓN</Botoncito>
        </SView>
        <SHr height={20}></SHr>
      </SView>
    </>
  }

  render_btn_aceptar() {
    return (
      <SButtom props={{ type: "outline" }} loading={this.state.loading} onPress={() => { this.form.submit(); }}>
        {this.key ? "Guardar" : "Crear"}
      </SButtom>
    );
  }
  render_btn_eliminar() {
    if (!this.key) return null;

    var usr = Model.usuario.Action.getByKey(this.key);
    return (
      <SButtom
        props={{ type: "danger" }}
        // loading={this.state.loading}
        onPress={() => {
          // this.setState({ loading: true });

          let values = { estado: 0 };

          SPopup.confirm({
            title: "Eliminar",
            message: "Esta seguro de eliminar?",
            onClose: function () {
              // this.setState({ loading: false });

              console.log("Se canceló de la eliminación de usuario")
            },
            onPress: function () {

              // this.setState({ loading: true });

              Model.usuario.Action.editar({
                key_usuario: Model.usuario.Action.getKey(),
                data: {
                  ...usr,
                  ...values,
                },
              })
                .then((resp) => {
                  // this.setState({ loading: false });
                  SNavigation.goBack();
                })
                .catch((e) => {
                  // this.setState({ loading: false });
                  SPopup.alert("Error en al eliminar el dato " + e.error_dato);
                });
            }
          });
        }}
      >
        {"Eliminar"}
      </SButtom>
    );
  }

  render_roles_y_sucursales() {
    var space = 24;
    if (!this.key) return null;
    var usr = Model.usuario.Action.getByKey(this.key);
    return (
      <SView col={"xs-12"} center>
        {/* <SHr height={space} /> */}
        {/* <SHr height={1} color={STheme.color.card} /> */}
        {/* <SHr height={space} /> */}

        {/* <HuellasDeUsuario key_usuario={this.key} /> */}
        {/* <SHr /> */}
        {/* <SView col={"xs-3.5"} center>
          <SincronizarUsuario key_usuario={this.key} />
        </SView> */}
        <SHr height={space} />
        <SHr height={1} color={STheme.color.card} />
        <SHr height={space} />
        <RolDeUsuario data={usr} />
        <SHr height={space} />
        <SHr height={1} color={STheme.color.card} />
        <SHr height={space} />
        {this.getSucursalesA()}
        <SHr height={space} />
      </SView>
    );
  }

  getSucursalesA() {
    if (
      !SSRolesPermisosValidate({
        page: "UsuarioPage",
        permiso: "editar_usuario_sucursal",
      })
    ) {
      return null;
    }
    if (!this.key) return null;
    return (
      <SView col={"xs-12"} center>
        <SText color={STheme.color.secondary} fontSize={14}>
          SUCURSALES ASIGNADAS
        </SText>
        <SHr />

        {/* <SView col={"xs-10"} row center>
          <SHr color='blue' height={1} />
        </SView> */}
        <Sucursal_usuario.Components.Select key_usuario={this.key} />
      </SView>
    );
  }

  responsables() {
    if (!this.key) return;

    return (
      <>
        <SView
          flex
          border={STheme.color.card}
          style={{ position: "absolute", top: 0, left: "5%", padding: 2 }}
        >
          {this.persona()}
        </SView>
      </>
    );
  }

  persona() {
    var usuarios = Model.usuario.Action.getAll();
    if (!usuarios) return <SLoad />;

    let datos = this.state?.data_usuario;
    if (!datos) return <SLoad />;

    return Object.keys(datos).map((key) => {
      let obj = datos[key];
      let fullname =
        usuarios[obj?.key_usuario]?.Nombres +
        " " +
        usuarios[obj?.key_usuario]?.Apellidos;
      return (
        <>
          <SText fontSize={13}>tipo: {obj?.tipo}</SText>
          {!obj?.key_usuario ? null : <SText fontSize={13}>{fullname}</SText>}
          <SText fontSize={13}>
            {new SDate(obj?.fecha_on).toString("yyyy-MM-dd hh:mm")}
          </SText>
          <SHr height={8} />
        </>
      );
    });
  }

  render() {
    return (
      <>
        <SPage title={"Registro"}>
          <SView col={"xs-12"} center>
            {this.responsables()}
            <SView col={"xs-11 sm-10 md-8 lg-6 xl-4"} center>
              {this.render_foto()}
              {/* {this.render_foto_documento()} */}
              {this.render_form()}
              <SView row col={"xs-12"} center>
                {this.render_btn_eliminar()}
                <SView width={8} />
                {this.render_btn_aceptar()}
              </SView>
              {/* {this.render_mapa()} */}


              {/* <Botoncito padding={8} onPress={() => { SNavigation.navigate("Huellas", { key: this.key }) }} row>
                <SIcon name='Fp' fill={STheme.color.lightGray} width={30} />{"Editar huellas"}
              </Botoncito>
              <SHr />
              <Botoncito padding={8} onPress={() => { SNavigation.navigate("/documentos", { key_usuario: this.key }) }} row>
                <SIcon name='Fp' fill={STheme.color.lightGray} width={30} />{"Documentos"}
              </Botoncito> */}
              <SHr />
            </SView>
            {this.render_roles_y_sucursales()}
          </SView>
        </SPage>
      </>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(RegistroCliente);
