import { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SLoad, SMath, SNavigation, SPage, SPopup, STable2, SText, STheme, SView } from 'servisofts-component';
import Model from '../../../Model';
import sucursal_usuario from '../../sucursal_usuario';
import Actions from '../Actions';
import FloatButtomSyncUsuario from '../../../Components/FloatButtomSyncUsuario';
import Botoncito from '../../../Components/Botoncito';
import SSocket from 'servisofts-socket'

class PaquetesVendidos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,

    };

  }
  componentDidMount() {
    Model.usuario.Action.getAll({ force: true });
  }

  getDetallePago(data) {
    switch (data.key_tipo_pago) {
      case "1":
        return `Número de serie: ${data["Número de serie"]}`;
      case "3":
        return `Banco: ${data["Banco"]}\nCodigo: ${data["Código"]} `;
      case "2":
        return `Nombre: ${data["Nombre"]}\nBanco: ${data["Banco"]}\nCodigo: ${data["Código"]} `;
      case "4":
        return `Beneficiario: ${data["Beneficiario"]}\nBanco: ${data["Banco"]}\nCodigo: ${data["Código"]} `;
    }
    return data.key_tipo_pago
  }


  getLista() {

    var data = Actions.getPaquetesVendidosAll({
      fecha_desde: this.fecha_inicio,
      fecha_hasta: this.fecha_fin
    }, this.props)
    var usuarios = Model.usuario.Action.getAll();
    var arr_f = sucursal_usuario.Actions.getActive(this.props);
    if (!arr_f) return <>
      <SText>Cargando Sucursales</SText>
      <SLoad />
    </>


    if (!data) return <>
      <SText>Cargando Paquetes vendidos</SText>
      <SLoad />
    </>

    // this.setState({ datos: data });




    if (!usuarios) return <>
      <SText>Cargando Usuarios</SText>
      <SLoad />
    </>

    // this.setState({ trabajo: data })
    // if(!this.state.trabajo) return;
    // console.log("mira ",this.state.trabajo)
    // console.log("sus ", usuarios)
    // tarea1
    // todo aqui tengo que mejorar Alvaro
    // la idea esque exporte todos los clientes
    return <STable2
      header={[
        { key: "index", label: "#", width: 40 },
        { key: "sucursal", label: "SUCURSAL", width: 90 },
        { key: "fecha_on", label: "FECHA REG.", width: 120, order: "desc", render: (item) => { return new SDate(item).toString("yyyy-MM-dd hh:mm") } },
        {
          key: "usuarios", label: "CLIENTES", width: 240, render: (item) => {
            let txt = "";
            if (Array.isArray(item)) {
              item.map((obj) => {
                txt += (usuarios[obj]?.Nombres + " " + usuarios[obj]?.Apellidos ?? "");
                txt += ((item.length > 1) ? ", " : "");
              })
            }
            return (txt).toUpperCase();
          }
        },

        // {
        //   key: "usuarios", label: "Cliente", width: 240, render: (item) => {
        //     return item.map((key_usr) => {
        //       return `${usuarios[key_usr]?.Nombres} ${usuarios[key_usr]?.Apellidos}`.toUpperCase()
        //     })
        //   },
        //   component: (arr) => { return <SText fontSize={11}>{arr.join(", ")}</SText> }
        // },
        { key: "usuarios-cantidad", label: "# CLI.", sumar: true, width: 60, center: true, render: (item) => { return item.length; }, component: (item) => { return <SText col={"col-xs-12"} backgroundColor={item > 1 ? "green" : "transparent"} color={STheme.color.text} center>{item}</SText> } },
        // { key: "usuarios-cantidad", label: "# CLI.", sumar: true, width: 60, center: true, render: (item) => { return SMath.formatMoney(item.length); } },
        { key: "paquete", label: "PAQUETE", width: 150 },
        { key: "observacion", label: "MOTIVO", width: 150 },
        { key: "total", label: "MONTO", sumar: true, width: 60, center: true },
        {
          key: "key_cajero", label: "ATC", width: 150, render: (item) => { return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}`.toUpperCase() },
          component: (item) => { return <SText fontSize={11} color={item == 'VENDEDOR QR' ? "green" : "white"} bold>{(item).toUpperCase()}</SText> }
        },
        {
          key: "nit", label: "NIT", width: 70,
          component: (item) => {
            return <SText fontSize={11} color='cyan'>{(item).toUpperCase()}</SText>
          }
        },
        { key: "razon_social", label: "RAZON SOCIAL", width: 190, component: (item) => { return <SText fontSize={11} color='cyan'>{(item).toUpperCase()}</SText> } },
        {
          key: "correos", label: "CORREOS", width: 220, render: (item) => {
            let txt = "";
            if (Array.isArray(item)) {
              item.map((obj) => {
                txt += (obj ?? "") + " ";
                txt += ((item.length > 1) ? ", " : "");
              })
            }
            return txt;
            // return (txt).toUpperCase();
          },
          // component: (item) => { return <SText fontSize={11} color='cyan'>{item}</SText> }
        },
        // alvaro
        // { key: "tipo_pago", label: "Tipo Pago", width: 150 },
        // {
        //     key: "caja_movimiento/data", label: "Detalle", width: 350, render: (item) => {
        //         return this.getDetallePago(item)
        //     }
        // },
        {
          key: "codigo_factura", label: "COD. FACT.", width: 75,
          component: (item) => {
            if (item) {
              return <SText color='yellow'># {item}</SText>
            } else {
              return;
            }
          }
        },

        {
          key: "-codigo_factura54", label: "EC.", width: 50,
          component: (item) => {
            if (item.codigo_factura) {
              return <SView backgroundColor={STheme.color.success} width={40} height={20}></SView>
            } else {
              return <SView backgroundColor={STheme.color.gray} width={40} height={20}></SView>
            }
          }
        },
        {
          key: "-codigo_factura5456", label: "ESTADO", width: 70,
          // filter_notin: "^0",
          // filter_h: "Pendiente",
          render: obj => {
            if (obj.codigo_factura) {
              return "Enviada";
            }
            else {
              return "Pendiente";
            }
          }
        },
        {
          key: "-onpress", label: "FACTURA", width: 100,
          renderExcel: (a) => "-",
          component: obj => {

            if (this.state.cargando_factura) return <SLoad />;
            if (obj.codigo_factura) {
              return <Botoncito height={20} style={{ backgroundColor: "#E30613" }} onPress={() => {
                this.setState({ cargando_factura: true })

                SPopup.confirm({
                  title: "Anular factura # " + obj.codigo_factura,
                  message: "Esta seguro de anular ?",

                  onClose: function () {
                    this.setState({ cargando_factura: false })
                    console.log("Se canceló la anulación de factura")
                  },
                  // onPress: function () {

                  onPress: () => {
                    SSocket.sendPromise({
                      component: "facturacion",
                      type: "anularFactura",
                      codigo_factura: obj.codigo_factura,
                    }).then(e => {
                      this.setState({ cargando_factura: false })
                      alert("La factura ha sido anulada con éxito. ¡Operación completada satisfactoriamente!");
                      console.log(e);
                      SSocket.sendPromise({
                        component: "paqueteVenta",
                        type: "anularFactura",
                        codigo_factura: obj.codigo_factura,
                      }).then(e => {
                        // alert("Campos borrados código de factura, NIT y razón social!");
                        console.log(e);
                        this.setState({ cargando_factura: false })

                      }).catch(e => {
                        console.error(e);
                        this.setState({ cargando_factura: false })
                        SPopup.alert("Error anular al factura.");
                      })
                    }).catch(e => {
                      console.error(e);
                      this.setState({ cargando_factura: false })
                      SPopup.alert("Error anular al factura.");
                    })
                  }
                });
              }}
              >Anular F.</Botoncito>
            }
            return <Botoncito style={{ backgroundColor: "#058DFF" }} height={20} onPress={() => {


              if (obj.codigo_factura) {
                SPopup.alert("Ya fue facturado");
                return;
              }
              this.setState({ cargando_factura: true })

              // const resp = await SSocket.sendPromise({
              //   component: "paquete_promo_usuario",
              //   type: "registro",
              //   data: formatear_data,
              //   estado: "cargando"
              // })
              // if (resp.estado == "exito") {
              //   data.estado = 2;
              //   this.setState({ ...this.state })
              // }

              SSocket.sendPromise({
                component: "facturacion",
                type: "facturarPaquete",
                key_paquete_venta: obj?.key,
                razon_social: obj?.razon_social,
                nit: obj?.nit,
              }).then(e => {
                console.log(e);
                this.setState({ cargando_factura: false })
                alert("La venta ha sido facturada con éxito")
              }).catch(e => {
                console.error(e);
                this.setState({ cargando_factura: false })
                if (e?.error) {
                  if (e?.error.indexOf("fac_codigo_producto") > -1) {
                    this.setState({ cargando_factura: false })
                    SPopup.alert("Error: El paquete no tiene configurado el codigo de producto ni la unidad de medida.")
                    return;
                  }
                  this.setState({ cargando_factura: false })
                  SPopup.alert(e.error);
                  return;
                }
                this.setState({ cargando_factura: false })
                SPopup.alert("Error desconocido al generar factura.");
              })
            }} >{"Facturar"}</Botoncito>
          }
        },
        {
          key: "-onpress", renderExcel: (a) => "", label: "PRINT FACT.", width: 100, component: obj => {

            if (!obj.codigo_factura) return;
            // console.log("ya ",obj)
            return <Botoncito height={20} onPress={() => {
              SSocket.sendPromise({
                component: "facturacion",
                type: "getFacturaPdfV2",
                codigo_factura: obj.codigo_factura + ""
              }).then(e => {
                const byteCharacters = atob(e.data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });

                // Create an object URL for the blob and open it
                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');
                console.log(e);
              }).catch(e => {
                console.error(e);
              })
            }} >{"Carta"}</Botoncito>
          }
        },
        {
          key: "-onpress", renderExcel: (a) => "", label: "ENVIAR FACTURA", width: 100, component: obj => {

            if (!obj.codigo_factura) return;
            return <Botoncito height={20} onPress={() => {
              SSocket.sendPromise({
                component: "facturacion",
                type: "enviarCorreos",
                codigo_factura: obj.codigo_factura + "",
                correos: obj.correos
              }).then(e => {
                // const byteCharacters = atob(e.data);
                // const byteNumbers = new Array(byteCharacters.length);
                // for (let i = 0; i < byteCharacters.length; i++) {
                //   byteNumbers[i] = byteCharacters.charCodeAt(i);
                // }
                // const byteArray = new Uint8Array(byteNumbers);
                // const blob = new Blob([byteArray], { type: 'application/pdf' });

                // // Create an object URL for the blob and open it
                // const blobUrl = URL.createObjectURL(blob);
                // window.open(blobUrl, '_blank');
                alert("FACTURA ENVIADA")
                console.log(e);
              }).catch(e => {
                console.error(e);
              })
            }} >{"CORREO"}</Botoncito>
          }
        },
      ]}
      filter={(item) => {
        return sucursal_usuario.Actions.isActive(item.key_sucursal, this.props)
      }}
      limit={100}
      data={data}
    />
  }
  render() {
    this.fecha_inicio = SNavigation.getParam("fecha_inicio", new SDate().toString("yyyy-MM-dd"));
    this.fecha_fin = SNavigation.getParam("fecha_fin", new SDate().toString("yyyy-MM-dd"));
    return (
      <SPage title={`Paquetes Vendidos (${this.fecha_inicio} / ${this.fecha_fin}) `} disableScroll>
        {/* <SText>{JSON.stringify(movimientos, "\n", "\t")}</SText> */}
        {this.getLista()}

        <FloatButtomSyncUsuario style={{ bottom: 90, }}
          onPress={() => {
            Model.usuario.Action.CLEAR_STORAGE()
          }}
        />
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(PaquetesVendidos);