import { SAssets } from "servisofts-component";


import Bag, { ReactComponent as BagW } from "./svg/Bag.svg";
import Calculadora, {
  ReactComponent as CalculadoraW,
} from "./svg/Calculadora.svg";
import Comment, { ReactComponent as CommentW } from "./svg/Comment.svg";
import CrowdfundingEstadoResultado, {
  ReactComponent as CrowdfundingEstadoResultadoW,
} from "./svg/CrowdfundingEstadoResultado.svg";
import CrowdfundingFijo, {
  ReactComponent as CrowdfundingFijoW,
} from "./svg/CrowdfundingFijo.svg";
import CrowdfundingVariable, {
  ReactComponent as CrowdfundingVariableW,
} from "./svg/CrowdfundingVariable.svg";
import DBPrestamo, {
  ReactComponent as DBPrestamoW,
} from "./svg/DBPrestamo.svg";
import DashboardBtn, {
  ReactComponent as DashboardBtnW,
} from "./svg/DashboardBtn.svg";
import DataBase, { ReactComponent as DataBaseW } from "./svg/DataBase.svg";
import HbCake1, { ReactComponent as HbCake1W } from "./svg/HbCake1.svg";
import HbFooterLeft1, {
  ReactComponent as HbFooterLeft1W,
} from "./svg/HbFooterLeft1.svg";
import HbFooterRight1, {
  ReactComponent as HbFooterRight1W,
} from "./svg/HbFooterRight1.svg";
import HbHeaderLeft1, {
  ReactComponent as HbHeaderLeft1W,
} from "./svg/HbHeaderLeft1.svg";
import HbHeaderRight1, {
  ReactComponent as HbHeaderRight1W,
} from "./svg/HbHeaderRight1.svg";
import Heart, { ReactComponent as HeartW } from "./svg/Heart.svg";
import Home, { ReactComponent as HomeW } from "./svg/Home.svg";
import LogoQR, { ReactComponent as LogoQRW } from "./svg/LogoQR.svg";
import Molinete, { ReactComponent as MolineteW } from "./svg/Molinete.svg";
import Pagos01, { ReactComponent as Pagos01W } from "./svg/Pagos01.svg";
import Profile, { ReactComponent as ProfileW } from "./svg/Profile.svg";
import Reloj, { ReactComponent as RelojW } from "./svg/Reloj.svg";
import Search, { ReactComponent as SearchW } from "./svg/Search.svg";
import TraspasoSucursal, {
  ReactComponent as TraspasoSucursalW,
} from "./svg/TraspasoSucursal.svg";
import Usuarios, { ReactComponent as UsuariosW } from "./svg/Usuarios.svg";
import Add, { ReactComponent as AddW } from "./svg/add.svg";
import Billetera, { ReactComponent as BilleteraW } from "./svg/billetera.svg";
import Chat, { ReactComponent as ChatW } from "./svg/chat.svg";
import ClienteActivo, {
  ReactComponent as ClienteActivoW,
} from "./svg/cliente_activo.svg";
import ClienteBecado, {
  ReactComponent as ClienteBecadoW,
} from "./svg/cliente_becado.svg";
import Fp, { ReactComponent as FpW } from "./svg/fp.svg";
import Logo, { ReactComponent as LogoW } from "./svg/logo.svg";
import LogoClean, { ReactComponent as LogoCleanW } from "./svg/logoclean.svg";
import Pdf, { ReactComponent as PdfW } from "./svg/pdf.svg";
import Verificado, { ReactComponent as VerificadoW } from "./svg/Verificado.svg";
import SyncUsuario, { ReactComponent as SyncUsuarioW } from "./svg/SyncUsuario.svg";
import AsistenciaUsuarios, { ReactComponent as AsistenciaUsuariosW } from "./svg/AsistenciaUsuarios.svg";
import Pruebas, { ReactComponent as PruebasW } from "./svg/Pruebas.svg";
import PaqueteCalendario, { ReactComponent as PaqueteCalendarioW } from "./svg/PaqueteCalendario.svg";
import PaquetePersona, { ReactComponent as PaquetePersonaW } from "./svg/PaquetePersona.svg";
import PaquetePrecio, { ReactComponent as PaquetePrecioW } from "./svg/PaquetePrecio.svg";
import DashboardAsistencia, { ReactComponent as DashboardAsistenciaW } from "./svg/modulo/DashboardAsistencia.svg";
import TomaAsistencia, { ReactComponent as TomaAsistenciaW } from "./svg/modulo/TomaAsistencia.svg";
import AsistenciaAlumnos, { ReactComponent as AsistenciaAlumnosW } from "./svg/modulo/AsistenciaAlumnos.svg";
import SCalendario, { ReactComponent as SCalendarioW } from "./svg/SCalendario.svg";
import SReloj, { ReactComponent as SRelojW } from "./svg/SReloj.svg";
import SVerificado, { ReactComponent as SVerificadoW } from "./svg/SVerificado.svg";

import SBilletera, { ReactComponent as SBilleteraW } from "./svg/SBilletera.svg";
import SFactura, { ReactComponent as SFacturaW } from "./svg/SFactura.svg";
const Assets: SAssets = {
  svg: {
    Logo: { Native: Logo, Web: LogoW },
    Calculadora: { Native: Calculadora, Web: CalculadoraW },
    CrowdfundingEstadoResultado: {
      Native: CrowdfundingEstadoResultado,
      Web: CrowdfundingEstadoResultadoW,
    },
    CrowdfundingFijo: { Native: CrowdfundingFijo, Web: CrowdfundingFijoW },
    CrowdfundingVariable: {
      Native: CrowdfundingVariable,
      Web: CrowdfundingVariableW,
    },
    Pagos01: { Native: Pagos01, Web: Pagos01W },
    DBPrestamo: { Native: DBPrestamo, Web: DBPrestamoW },
    Billetera: { Native: Billetera, Web: BilleteraW },
    Fp: { Native: Fp, Web: FpW },
    Heart: { Native: Heart, Web: HeartW },
    Comment: { Native: Comment, Web: CommentW },
    LogoClean: { Native: LogoClean, Web: LogoCleanW },
    Add2: { Native: Add, Web: AddW },
    Chat: { Native: Chat, Web: ChatW },
    Profile: { Native: Profile, Web: ProfileW },
    Bag: { Native: Bag, Web: BagW },
    Search2: { Native: Search, Web: SearchW },
    Home: { Native: Home, Web: HomeW },
    Pdf: { Native: Pdf, Web: PdfW },
    HbCake1: { Native: HbCake1, Web: HbCake1W },
    HbFooterLeft1: { Native: HbFooterLeft1, Web: HbFooterLeft1W },
    HbFooterRight1: { Native: HbFooterRight1, Web: HbFooterRight1W },
    HbHeaderLeft1: { Native: HbHeaderLeft1, Web: HbHeaderLeft1W },
    HbHeaderRight1: { Native: HbHeaderRight1, Web: HbHeaderRight1W },
    DashboardBtn: { Native: DashboardBtn, Web: DashboardBtnW },
    TraspasoSucursal: { Native: TraspasoSucursal, Web: TraspasoSucursalW },
    Molinete: { Native: Molinete, Web: MolineteW },
    ClienteActivo: { Native: ClienteActivo, Web: ClienteActivoW },
    ClienteBecado: { Native: ClienteBecado, Web: ClienteBecadoW },
    Usuarios: { Native: Usuarios, Web: UsuariosW },
    DataBase: { Native: DataBase, Web: DataBaseW },
    LogoQR: { Native: LogoQR, Web: LogoQRW },
    Reloj: { Native: Reloj, Web: RelojW },
    Verificado: { Native: Verificado, Web: VerificadoW },
    SyncUsuario: { Native: SyncUsuario, Web: SyncUsuarioW },
    AsistenciaUsuarios: { Native: AsistenciaUsuarios, Web: AsistenciaUsuariosW },
    Pruebas: { Native: Pruebas, Web: PruebasW },
    PaqueteCalendario: { Native: PaqueteCalendario, Web: PaqueteCalendarioW },
    PaquetePersona: { Native: PaquetePersona, Web: PaquetePersonaW },
    PaquetePrecio: { Native: PaquetePrecio, Web: PaquetePrecioW },
    DashboardAsistencia: { Native: DashboardAsistencia, Web: DashboardAsistenciaW },
    TomaAsistencia: { Native: TomaAsistencia, Web: TomaAsistenciaW },
    AsistenciaAlumnos: { Native: AsistenciaAlumnos, Web: AsistenciaAlumnosW },
    SCalendario: { Native: SCalendario, Web: SCalendarioW },
    SReloj: { Native: SReloj, Web: SRelojW },
    SVerificado: { Native: SVerificado, Web: SVerificadoW },
    SFactura: { Native: SFactura, Web: SFacturaW },
    SBilletera: { Native: SBilletera, Web: SBilleteraW },
  },
};

export default Assets;
